/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/** Padronização para animação de borda **/

.border-animated {
  background: var(--ion-holder-border);
  background-size: 200% 200%;
  animation: borderAnimation 4s ease-in-out infinite;
  

  width: 358px;
  height: 428px;
  margin: 0 auto !important;
}

@keyframes borderAnimation {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

/** Posicionamento de Toast (Ionic) **/

.toastAfterHeader {
  .toast-wrapper {
    transform: translateY(100px) !important;
  }
}

/** Personalização do tamanho dos Modais **/

.modal-size-80 .modal-wrapper {
  min-height: 80%;
  min-width: 80%;
  position: absolute;
  display: block;
}

/** Padronização de CARDs Ionic **/

.card-ipp {
  --color: white !important;
  text-align: center;
  align-items: center;
  justify-content: center;

  width: 350px;
  height: 420px;
  margin: 0 auto !important;

  --background: var(--ion-card-background);
}

.card-ipp:hover {
  cursor: pointer;
  background: rgba(65, 65, 65, 0.801);

  img {
    transform: scale(0.8);
  }
}

.card-ipp img {
  max-width: 250px;
  max-height: 250px;
  transition: transform 0.2s;
  /* Animation */
  padding: 5px 10px;
  margin: auto;
}

.card-ipp ion-card-title {
  --color: white !important;
}

.card-ipp ion-card-content {
  --color: white !important;
  margin-top: 5px;
}

.card-ipp ion-card-title {
  font-weight: bold;
}

/** Padronização para Splitter **/

.card-splitter {
  --color: white !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  margin: 0 auto !important;

  --background: var(--ion-card-background);

  font-size: 2vh;
}

.card-splitter:hover {
  cursor: pointer;
  background: rgba(65, 65, 65, 0.801);

  img {
    transform: scale(0.8);
  }
}

.card-splitter .card-margin-top {
  margin-top: 100px;
}

.card-splitter img {
  max-width: 500px !important;
  max-height: 500px !important;
  transition: transform 0.2s;
  /* Animation */
  padding: 5px 10px;
  margin: auto;
}

.card-splitter ion-card-title {
  --color: white !important;
  font-size: 3vh;
}

.card-splitter ion-card-content {
  --color: white !important;
  margin-top: 5px;
  font-size: 2.3vh;
}

.card-splitter ion-card-title {
  font-weight: bold;
}

.splitter-container {
  height: 90%;
  width: 90%;
  margin: 5px auto;
}

.background-icon {
  margin-top: 25px;
  font-size: 48px;
}

/** Logo IPP no topo */
ion-title {
  margin-right: 20px;
  line-height: 30px;
  border-radius: 5px;
  background: var(--logo-rodape-png);
}

ion-item {
  --border-color: var(--text-color) !important;
}

ion-list {
  border-bottom-color: var(--text-color) !important;
}

/** Default form **/

.default-form {
  width: 90%;
  margin: auto;
  margin-top: 5vh;

  .border {
    border: 2px groove var(--border-color-outline);
    border-radius: 23px;
    background: var(--back-ground-form);
  }

  .limiter {
    max-width: 90%;
    margin: auto;
  }

  .p-dropdown {
    width: 100% !important;
  }

  .p-inputtextarea {
    height: 100% !important;
  }

  .p-dropdown-filter {
    width: 100% !important;
  }

  p-inputmask {
    width: 100% !important;
  }

  .p-inputmask {
    width: 100% !important;
  }

  .btn-end {
    text-align: end !important;

    p-button .p-button {
      min-width: 90px !important;
      float: right;
    }

    margin-top: 5px !important;
    margin-bottom: 10px !important;

  }

  .btn-start {
    text-align: start !important;

    p-button .p-button {
      min-width: 90px !important;
      float: left;
    }

    margin-top: 5px !important;
    margin-bottom: 10px !important;
  }

  .change-disabled-color {
    .ng-pristine {
      .p-filled {
        background-color: var(--same-line) !important;
        border: none !important;
        color: var(--border-color-outline) !important;
      }

      .p-float-label>label {
        background-color: var(--same-line) !important;
        border: none !important;
        color: var(--border-color-outline) !important;
      }

      background-color: var(--same-line) !important;
      border: none !important;
      color: var(--border-color-outline) !important;
    }
  }
}

//

.project-icon-pn {
  font-size: 35px;
}

.adjust-panel {
  .p-panel {
    width: 85% !important;
    height: 95% !important;
    margin: auto;
    margin-top: 15px;
  }


}

.adjust-form {

  p-dropdown {
    width: 100% !important;
  }

  .p-dropdown {
    width: 100% !important;
  }

  .p-dropdown-trigger {
    width: 100% !important;
  }

  .p-inputwrapper{
    width: 100% !important;
  }

  .p-inputtextarea {
    height: 100% !important;
    width: 100% !important;
  }

  .p-dropdown-filter {
    width: 100% !important;
  }

  p-inputmask {
    width: 100% !important;
  }

  .p-inputmask {
    width: 100% !important;
  }

  .p-inputtext {
    width: 100% !important;
  }

  p-password {
    max-height: 37px !important;
  }

  .p-password {
    width: 100% !important;
    max-height: 37px !important;
  }
}

.max-w-98{
  max-width: 98%;
}